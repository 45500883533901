import * as React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"
import './lessons.css'

const Lessons = () => {

  return (
    <>
      <Seo title="Oboe Lessons" description="Do you want to start with oboe lessons in The Hague or Montfoort? I am an experienced teacher. I am able to adapt my teaching style to the level and expectances of every student, from the very first steps up to the admission to a Conservatory. I also teach the auxiliary instruments (Cor Anglais and oboe d’amore), reed-making, and music theory."/>
      <Layout>
        <section className="main-lessons">
            <div className="left">
              <h2 className="sub-title">Oboe Lessons</h2>
              <p>I teach oboe since 2013, privately (in The Hague and Utrecht), at the Hillegomse Muziekschool, and at Vereniging Sint Carolus in Montfoort.</p>
              <p>I am an experienced teacher. I am able to adapt my teaching style to the level and expectances of every student, from the very first steps up to the admission to a Conservatory. I also teach the auxiliary instruments (Cor Anglais and oboe d’amore), reed-making, and music theory.</p>
              <p>Would you like to start (again) to play the oboe, or do you have other questions? Do not hesitate to <Link to="../contact" className="contact-link">contact</Link> me for advice or a trial lesson!</p>
            </div>
            <div className="right">
              <StaticImage 
                  src="../../images/5492.jpg"
                  alt="Quentin" 
                  placeholder="blurred"
                  layout="constrained">
              </StaticImage>
            </div>
        </section>
      </Layout>
    </>
  )
}

export default Lessons

